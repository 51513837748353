import React, { useCallback, useMemo } from "react";
import StateManagedSelect, { StylesConfig } from "react-select";

import { ColorRGB, HEXtoRGB, RGBAtoString } from "../../shared/functions/colorsMath";

import Spacer from "../Spacer";
import Text, { TextColor, TextProps } from "../Text";

export interface SelectOption {
  value: string;
  label: string;
}

export const SelectOptionDefault = undefined;

interface SelectProps {
  placeholder: string;
  value: SelectOption | undefined;
  options: SelectOption[];
  onChange?: (opt?: SelectOption) => void;
  className?: string;
  width?: string;
  isOutlined?: boolean;
  title?: TextProps;
  disabled?: boolean;
  required?: boolean;
}

const Select: React.VFC<SelectProps> = ({
  placeholder = "Select ...",
  value,
  disabled = false,
  required = false,
  options,
  onChange,
  className = "",
  width = "52%",
  isOutlined = false,
  title,
}) => {
  const _getClr = useCallback((alpha: number, isInvert: boolean = false) => {
    if (isInvert) {
      return `rgba(255, 255, 255, ${alpha})`;
    }
    return `rgba(0, 0, 0, ${alpha})`;
  }, []);

  const _fgClr = useMemo(() => _getClr(1), [_getClr]);
  const _fgIClr = useMemo(() => _getClr(1, true), [_getClr]);
  const _bgClr = useMemo(
    () => (isOutlined ? "#fff" : _getClr(0.05)),
    [_getClr, isOutlined]
  );
  const _phClr = useMemo(() => _getClr(0.35), [_getClr]);
  const _shdwClr = useMemo(() => _getClr(0.1), [_getClr]);

  const styles = useMemo<StylesConfig>(
    () => ({
      indicatorsContainer: () => ({ border: "none" }),
      singleValue: (provided) => ({
        ...provided,
        color: _fgClr,
      }),
      container: (provided) => ({ ...provided }),
      control: (provided, state) => {
        provided["&:hover"] = undefined;

        let bgClr = _bgClr;
        if (state.isDisabled) {
          bgClr = _shdwClr;
        }

        return {
          ...provided,

          width: width,
          color: _getClr(1, true),
          backgroundColor: bgClr,
          borderRadius: "8px",
          border: isOutlined ? `2px solid ${_shdwClr}` : "0px solid rgba(0,0,0,0)",
          boxShadow: "none",
          outline: "none",
          transition: "all 0.35s ease-in-out",
          fontSize: "20px",
          lineHeight: "38px",

          ":focus": {
            borderColor: _phClr,
          },
          ":active": {
            borderColor: _phClr,
          },
          ":hover": {
            borderColor: _phClr,
          },
        };
      },
      placeholder: (provided) => ({
        ...provided,
        color: _phClr,
      }),
      option: (provided, state) => {
        let color = _getClr(0.8);
        let backgroundColor = _fgIClr;

        if (state.isFocused) {
          backgroundColor = RGBAtoString(HEXtoRGB("#F7C706") as ColorRGB, 0.1);
        }
        if (state.isDisabled) {
          color = _phClr;
        }
        if (state.isSelected || state.isMulti || state.isRtl) {
          color = _fgIClr;
          backgroundColor = "#F7C706";
        }

        return {
          ...provided,
          borderRadius: "5px",
          color,
          backgroundColor,
          padding: "12px 10px",
          margin: "4px 0px",
          transition: "all 0.15s ease-in-out",

          ":active": {
            color: _fgIClr,
            backgroundColor: RGBAtoString(HEXtoRGB("#F7C706") as ColorRGB, 0.8),
          },
        };
      },
      menu: (provided) => ({
        ...provided,
        color: _fgClr,
        backgroundColor: _fgIClr,
        position: "relative",
        borderRadius: "8px",
        width: "407px",
      }),
      menuList: (provided) => ({
        ...provided,
        color: _fgClr,
        backgroundColor: _fgIClr,
        width: "407px",
        borderRadius: "8px",
        boxShadow: `0px 0px 60px ${_shdwClr}`,
        padding: "5px 8px",

        overflow: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }),
      menuPortal: (provided) => ({
        ...provided,
        width: "407px",
        zIndex: 9999,
      }),
    }),
    [_bgClr, _fgClr, _fgIClr, _getClr, _phClr, _shdwClr, isOutlined, width]
  );

  return (
    <div className={className}>
      {title ? (
        <>
          <div className='d-flex'>
            <Spacer left='8px' />
            <Text color={TextColor.GreyDark} {...title} />
          </div>
          <Spacer bottom='3px' />
        </>
      ) : null}

      <StateManagedSelect
        isDisabled={!!disabled}
        menuPortalTarget={document.body}
        styles={styles}
        placeholder={placeholder}
        options={options}
        value={value}
        defaultValue={value}
        className={`${className ?? ""}`}
        onChange={(opt) => {
          if (onChange) {
            onChange(opt as SelectOption | undefined);
          }
        }}
      />

      {!disabled ? (
        <input
          type={"hidden"}
          defaultValue={value?.label}
          value={value?.label}
          required={required}
        />
      ) : null}
    </div>
  );
};

export default Select;
