import React, { CSSProperties, useCallback, useMemo, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import ReactModal from "react-modal";

import MotionDiv, { MotionDivTransition } from "../../../common/MotionDiv";
import Block from "../../../common/Block";
import Spacer from "../../../common/Spacer";
import Input from "../../../common/Input";
import LazyImage from "../../../common/LazyImage";
import ButtonIco from "../../../common/ButtonIco";
import Button, { ButtonType } from "../../../common/Button";
import Text, { TextColor, TextType } from "../../../common/Text";
import Icon, { IconColor, IconType } from "../../../common/Icon";

import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import { formatBytes } from "../../../shared/functions/formatBytes";
import APIService, {
  APIDataMedia,
  APIServicePaths,
  API_LIMIT_UPLOAD_FILES,
  API_LIMIT_UPLOAD_MEDIA_TYPES,
  API_LIMIT_UPLOAD_SIZE,
} from "../../../services/APIService";
import Select, { SelectOption } from "../../../common/Select";

interface RIAIPCButtonStyledProps {
  backgroundColor: CSSProperties["backgroundColor"];
  size?: CSSProperties["width"];
}
const RIAIPCButtonStyled = styled.div<RIAIPCButtonStyledProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 14px;
  top: 14px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: background-color 0.35s ease-in-out;

  &::hover {
    cursor: pointer;
  }
`;

interface RIAIPCWrapperProps {
  imgURL: string;
  isEditorHidden: boolean;
  onClickDelete: () => void;
  onClick: (imgURL: string) => void;
  isFullWidthPreview: boolean;
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
}

const RIAIPCWrapper: React.VFC<RIAIPCWrapperProps> = ({
  imgURL,
  isEditorHidden,
  onClickDelete,
  onClick,
  isFullWidthPreview,
  height = "380px",
}) => {
  return (
    <div
      style={{ position: "relative" }}
      className={`on-hover ${isFullWidthPreview ? "attachment-image-preview-cell" : ""}`}>
      {isEditorHidden ? null : (
        <RIAIPCButtonStyled
          backgroundColor={"rgba(0,0,0,0.5)"}
          onClick={(e) => {
            e.preventDefault();
            onClickDelete();
          }}>
          <ButtonIco
            icon={{
              type: IconType.Cross,
              size: "20px",
              color: IconColor.White,
              opacity: "100%",
            }}
            backgroundColor={"rgba(255,255,255,0.35)"}
            size='24px'
            className='on-hover'
          />
        </RIAIPCButtonStyled>
      )}

      <div
        className='attachment-image-preview'
        style={{
          height,
        }}>
        <LazyImage
          onClick={(e) => {
            e.preventDefault();
            onClick(imgURL);
          }}
          src={imgURL}
          width='100%'
          className='attachment-image-preview-img'
        />
      </div>
    </div>
  );
};

interface UCFormData {
  title: string;
  description: string;
  industry: string;
  function: SelectOption | undefined;
  provider: string;
  implemented: string;
  cover?: APIDataMedia;
}

const defaultFormData: UCFormData = {
  title: "",
  description: "",
  industry: "",
  function: undefined,
  provider: "",
  implemented: "",
};

interface UseCaseCreationProps {
  onSubmit: () => void;
  companyData: { name: string };
  verificationCode: string;
}

const UseCaseCreation: React.VFC<UseCaseCreationProps> = ({
  onSubmit,
  companyData,
  verificationCode,
}) => {
  const [windowWidth] = useWindowDimensions();

  const [error, setError] = useState("");

  const [formData, setFormData] = useState<UCFormData>({ ...defaultFormData });
  const onChangeFormData = useCallback(
    (value: UCFormData[keyof UCFormData], dataName: keyof UCFormData): void => {
      if (error?.length) {
        setError("");
      }

      const oldValue = formData[dataName];
      if (oldValue !== value) {
        setFormData({ ...formData, [dataName]: value });
      }
    },
    [formData, error]
  );

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
  const onStartRequest = useCallback(() => setIsRequestPending(true), []);
  const onStopRequest = useCallback(() => setIsRequestPending(false), []);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filesAddError, setFilesAddError] = useState<string>("");
  const [isFileDropOverFrame, setIsFileDropOverFrame] = useState(false);
  const onDragLeaveHandler = useCallback(() => {
    if (isFileDropOverFrame) {
      setIsFileDropOverFrame(false);
    }
  }, [isFileDropOverFrame]);
  const onDragEnterHandler = useCallback(() => {
    if (!isFileDropOverFrame) {
      setIsFileDropOverFrame(true);
    }
  }, [isFileDropOverFrame]);
  const onUpdateFile = useCallback(
    async (files: FileList | null) => {
      if (!files || !files?.length) {
        return;
      } else if (files.length > API_LIMIT_UPLOAD_FILES) {
        setFilesAddError(
          `Selected too many files at once. Maximum: ${API_LIMIT_UPLOAD_FILES}.`
        );
        return;
      }

      if (filesAddError?.length) {
        setFilesAddError("");
      }

      onStartRequest();

      const oldValue = formData.cover;
      let newValue: File = files.item(0) as File;

      const ext = newValue?.name?.split(".")?.pop()?.toLocaleLowerCase() ?? newValue.name;
      let currentFileError = "";
      if (!API_LIMIT_UPLOAD_MEDIA_TYPES.includes(ext)) {
        currentFileError += `"${newValue.name}": Invalid file extension ".${ext}".\n`;
      }

      if (oldValue) {
        if (oldValue.size === newValue.size && oldValue.name === newValue.name) {
          currentFileError += `"${newValue.name}": File already was added.\n`;
        }
      }

      if (API_LIMIT_UPLOAD_SIZE < newValue.size) {
        currentFileError += `"${newValue.name}": File size(${formatBytes(
          newValue.size
        )}) bigger than allowed: ${formatBytes(API_LIMIT_UPLOAD_SIZE)}.\n`;
      }

      if (currentFileError?.length) {
        setFilesAddError(currentFileError);
      } else {
        const resp = await APIService.postUpload(newValue).then((resp) => resp.data);

        if (resp?.length) {
          onChangeFormData(resp[0], "cover");
        } else {
          currentFileError += "Can't upload selected file..";
        }
      }

      if (currentFileError !== filesAddError) {
        setFilesAddError(currentFileError);
      }

      onStopRequest();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filesAddError, formData.cover, onChangeFormData]
  );

  console.log(formData);

  const _functionOptions = useMemo(
    () => [
      { label: "Marketing", value: "Marketing" },
      { label: "HR", value: "HR" },
      { label: "Finance", value: "Finance" },
      { label: "IT", value: "IT" },
      { label: "Supply Chain", value: "Supply Chain" },
      { label: "Operations", value: "Operations" },
      { label: "Risk Management", value: "Risk Management" },
      { label: "E-commerce & Sales", value: "E-commerce & Sales" },
      { label: "Legal", value: "Legal" },
      { label: "Customer Service & Engagement", value: "Customer Service & Engagement" },
    ],
    []
  );

  const [imgPreview, setImgPreview] = useState<string | undefined>(undefined);
  const onSetImgPreview = useCallback(
    (img: string) => {
      if (!imgPreview) {
        setImgPreview(img);
      }
    },
    [imgPreview]
  );
  const onClearImgPreview = useCallback(() => {
    if (imgPreview) {
      setImgPreview(undefined);
    }
  }, [imgPreview]);

  const isSubmitDisabled = useMemo(
    () =>
      !formData?.cover ||
      !formData?.title?.length ||
      !formData?.description?.length ||
      !formData?.industry?.length ||
      !formData?.function?.value?.length ||
      !formData?.provider?.length ||
      !formData?.implemented?.length,
    [formData]
  );

  const onSubmitHandler = useCallback(async () => {
    onStartRequest();
    if (error?.length) {
      setError("");
    }

    const _uploadData = {
      code: verificationCode,
      title: formData.title,
      subtitle: formData.implemented,
      description: formData.description,
      previewPhoto: formData.cover?.id ?? null,
      provider: formData.provider,
      industryFocus: formData.industry,
      function: formData.function?.value ?? null,
      customerUsed: formData.implemented,
    };

    const resp = await APIService.post(APIServicePaths.UseCase, _uploadData).then(
      (resp) => resp.data,
      () => {
        setError("Can't create new Use Case. Try again later...");
      }
    );

    if (resp) {
      onSubmit();
      setFormData({ ...defaultFormData });
    }

    onStopRequest();
  }, [
    error?.length,
    formData.cover?.id,
    formData.description,
    formData.function?.value,
    formData.implemented,
    formData.industry,
    formData.provider,
    formData.title,
    onStartRequest,
    onStopRequest,
    onSubmit,
    verificationCode,
  ]);

  return (
    <MotionDiv>
      <ReactModal
        ariaHideApp={false}
        isOpen={!!imgPreview?.length}
        className={`app-modal auto-center app-modal-preview`}
        onRequestClose={onClearImgPreview}
        overlayClassName={`app-overlay`}>
        <RIAIPCWrapper
          isEditorHidden={false}
          isFullWidthPreview={false}
          imgURL={imgPreview as string}
          onClick={onClearImgPreview}
          onClickDelete={onClearImgPreview}
          height='100%'
          width='100%'
        />
      </ReactModal>

      <Block
        isThin
        width={windowWidth <= 925 ? "100%" : "880px"}
        className='f-col'
        style={{
          overflow: "hidden",
        }}>
        <Text isExtraBold type={TextType.Large} color={TextColor.GreyDark}>
          New Use Case
        </Text>

        <Spacer top='40px' />

        <Text color={TextColor.GreyDark} type={TextType.Middle}>
          On behalf of<strong> {companyData.name} </strong>you’ve been invited to make a
          unique Use Case which is going to be available within 7Hills idea management
          system. Please, provide all the data necessary. Thank you so much for your
          participation.
        </Text>

        <Spacer bottom='16px' />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitHandler();
          }}>
          <Input
            required
            disabled={isRequestPending}
            title={{ text: "Title" }}
            style={{ width: "100%", fontSize: "20px", lineHeight: "32px" }}
            placeholder={"Input a Use Case title"}
            value={formData.title}
            onChange={(value) => onChangeFormData(value, "title")}
          />

          <Spacer top='14px' />

          <Input
            required
            disabled={isRequestPending}
            isTextarea
            minRows={5}
            title={{ text: "Description" }}
            wrapperStyle={{ width: "100%" }}
            style={{
              width: "100%",
              fontSize: "20px",
              lineHeight: "32px",
              fontFamily: "Roboto",
            }}
            placeholder={"Input a Use Case description"}
            value={formData.description}
            onChange={(value) => onChangeFormData(value, "description")}
          />

          <Spacer top='74px' />

          <div className='d-flex w-100'>
            <Input
              required
              disabled={isRequestPending}
              title={{ text: "Industry focus" }}
              style={{ width: "100%", fontSize: "20px", lineHeight: "32px" }}
              placeholder={"Input an Industry focus"}
              value={formData.industry}
              onChange={(value) => onChangeFormData(value, "industry")}
            />

            <Spacer left='16px' />

            <Select
              value={undefined}
              disabled={isRequestPending}
              required
              options={_functionOptions}
              onChange={(opt) => onChangeFormData(opt, "function")}
              placeholder='Select a function'
              className='w-100 f-col'
              width='100%'
              title={{ text: "Function" }}
              isOutlined
            />
          </div>

          <Spacer top='14px' />

          <div className='d-flex w-100'>
            <Input
              required
              disabled={isRequestPending}
              title={{ text: "Provider" }}
              style={{ width: "100%", fontSize: "20px", lineHeight: "32px" }}
              placeholder={"Input a company name"}
              value={formData.provider}
              onChange={(value) => onChangeFormData(value, "provider")}
            />

            <Spacer left='16px' />

            <Input
              required
              disabled={isRequestPending}
              title={{ text: "Implemented by" }}
              style={{ width: "100%", fontSize: "20px", lineHeight: "32px" }}
              placeholder={"Input the names of companies"}
              value={formData.implemented}
              onChange={(value) => onChangeFormData(value, "implemented")}
            />
          </div>

          <Spacer top='100px' />

          <AnimatePresence exitBeforeEnter>
            {formData.cover ? (
              <MotionDiv
                key={"img-preview"}
                className='d-flex f-ccenter attachment-image-preview-img-grid'>
                <RIAIPCWrapper
                  isEditorHidden={false}
                  isFullWidthPreview={true}
                  imgURL={APIService.getImageURL(formData.cover.url)}
                  onClick={onSetImgPreview}
                  onClickDelete={() => onChangeFormData(undefined, "cover")}
                  height='250px'
                  width='100%'
                />
              </MotionDiv>
            ) : (
              <MotionDiv key={"img-select"} className='use-case-cover-wrapper'>
                <input
                  style={{
                    display: "none",
                  }}
                  required
                  disabled={isRequestPending}
                  value={undefined}
                  id='riafcfiht'
                  name='riafcfiht'
                  onChange={(e) => {
                    onUpdateFile(e.target.files);
                  }}
                  type='file'
                  ref={fileInputRef}
                />

                <FileDrop
                  onDrop={(files) => {
                    onUpdateFile(files);
                    onDragLeaveHandler();
                  }}
                  onFrameDragEnter={onDragEnterHandler}
                  onFrameDragLeave={onDragLeaveHandler}
                  onDragOver={onDragEnterHandler}
                  onDragLeave={onDragLeaveHandler}
                  onFrameDrop={onDragLeaveHandler}
                  targetClassName='d-flex f-col f-acenter'>
                  <Icon type={IconType.Image} size='64px' opacity='50%' />

                  <Spacer bottom='25px' />

                  <AnimatePresence exitBeforeEnter={true}>
                    {!isFileDropOverFrame ? (
                      <MotionDiv
                        key='riaic-drag-drop'
                        className='d-flex f-col f-acenter'
                        transition={MotionDivTransition.Fast}>
                        <Text isExtraBold color={TextColor.GreyDark}>
                          Add Cover
                        </Text>

                        <Spacer top='5px' />

                        <Text
                          color={TextColor.GreyDark}
                          isCenter
                          noWrap={isRequestPending}>
                          {isRequestPending
                            ? "Uploading..."
                            : "Drag and drop JPEG, GIF or PNG file into this area"}
                        </Text>

                        <Spacer top='10px' />

                        <Button
                          isTool
                          type={ButtonType.Grey}
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}>
                          <Text>Add Cover</Text>
                        </Button>
                      </MotionDiv>
                    ) : (
                      <MotionDiv
                        key='riaic-drag-drop-hover'
                        transition={MotionDivTransition.Fast}>
                        <Text color={TextColor.GreyDark} width='303px' isCenter>
                          {"Drag them here..."}
                        </Text>
                        <Spacer bottom='20px' />{" "}
                      </MotionDiv>
                    )}
                  </AnimatePresence>
                </FileDrop>

                {filesAddError?.length ? (
                  <div>
                    <pre
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        boxShadow: "none",
                      }}>
                      <Text color={TextColor.Red}>{filesAddError}</Text>
                    </pre>
                  </div>
                ) : null}
              </MotionDiv>
            )}
          </AnimatePresence>

          <Spacer top='70px' />

          <div
            className='d-flex f-col f-acenter f-ccenter'
            style={{
              padding: "24px",
              width: "calc(100% + 48px)",
              marginLeft: "-24px",
              marginBottom: "-24px",
              background: "rgba(255, 255, 255, 0.9)",
              boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(20px)",
            }}>
            <Button
              btnType='submit'
              type={ButtonType.Primary}
              disabled={isSubmitDisabled}
              style={{
                padding: "12px 32px",
              }}
              className='d-flex on-hover'>
              <Text
                isExtraBold
                color={TextColor.White}
                style={{
                  textShadow: "0px 1px 2px rgba(0, 0, 0, 0.35)",
                }}
                className='welcome-block-text'>
                Send
              </Text>
            </Button>
          </div>
        </form>
      </Block>
    </MotionDiv>
  );
};

export default UseCaseCreation;
