import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";

import Icon, { IconType } from "./common/Icon";

import UseCasesPage from "./pages/UseCaseCreationPage";
import ErrorPage from "./pages/ErrorPage";

export enum AppRoutingPaths {
  UseCase = "/use-case",

  Error = "/404",
}

interface AppStyledProps {}
const AppStyled = styled.div<AppStyledProps>`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;

  gap: 65px;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  background: url(/assets/bg.jpg) #ffcc00 center no-repeat;

  background-size: cover;
  background-attachment: fixed;

  & ::selection {
    background-color: #ffcc00;
    color: #000;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const App: React.VFC = () => {
  const location = useLocation();

  useEffect(() => { window.scrollTo({ top: 0, behavior: "smooth", }); }, [location]);

  return (
    <AppStyled>
      <Icon type={IconType.Logo} opacity={"100%"} height={"52px"} width={"235px"} />

      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path={AppRoutingPaths.UseCase} element={<UseCasesPage />} />
          <Route path={AppRoutingPaths.Error} element={<ErrorPage />} />

          <Route path='*' element={<Navigate to={AppRoutingPaths.Error} />} />
        </Routes>
      </AnimatePresence>
    </AppStyled>
  );
};

export default App;
